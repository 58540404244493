import React, { ReactElement } from 'react'
import Layout from '../../container/layout'
import { Banner } from '../../components/thanks/'
import { Heading } from '../../components/shared'
import styled from 'styled-components'

const Container = styled.article`
  background-color: #6fd8b2;
  font-weight: 300;
  border-left: 12px solid #fff;
  border-right: 12px solid #fff;
  text-align: center;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 20%;
`

const ThanksPage: React.FC = (): ReactElement => (
  <Layout>
    <Banner />
    <Container>
      <Heading id={'thanks'} />
    </Container>
  </Layout>
)

export default ThanksPage
